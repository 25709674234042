import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'profile',
        path: '/profile',
        component: () => import('./pages/users-profile.vue'),
        meta: {
            permission: 'access',
        },
    },
    {
        name: 'two-factor-auth.create',
        path: '/two-factor-auth/create',
        component: () => import('./pages/two-factor-auth.vue'),
        meta: {
            permission: 'access',
        },
    },
    {
        name: 'two-factor-auth.edit',
        path: '/two-factor-auth/edit',
        component: () => import('./pages/two-factor-auth-edit.vue'),
        meta: {
            permission: 'access',
        },
    },
]

export default {
    routes,
    meta: { layout: 'dashboard-layout', guard: GUARD.TENANT },
}
