<template>
    <base-sticky-heading
        :title="$t('users.twoFactorAuth.title')"
        :description="$t('users.twoFactorAuth.description')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="default"
                    @click="$router.push({ name: 'profile' })"
                >
                    {{ $t('general.cancel') }}
                </base-button>
            </div>
        </template>

        <div class="px-6">
            <base-section
                :title="$t('users.twoFactorAuth.sectionTitle')"
                :description="$t('users.twoFactorAuth.sectionDescription')"
                class="text-gray-700"
            >
                <template #right>
                    <div class="mb-4 text-sm">
                        {{ $t('users.twoFactorAuth.alreadyEnabled') }}
                    </div>
                    <div class="mb-4 text-sm">
                        {{ $t('users.twoFactorAuth.recoveryCodes') }}
                    </div>
                    <div class="mb-4 rounded-xl border px-3 py-2" id="codes">
                        <ul class="ml-5 list-disc">
                            <li v-for="item in recoveryCodes" :key="item">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                    <div class="mb-4 flex gap-3">
                        <base-button
                            variant="default"
                            icon="line-icons:general:copy-01"
                            @click="onCopyCodes"
                        >
                            {{ $t('users.twoFactorAuth.copyCodes') }}
                        </base-button>
                        <base-button
                            variant="default"
                            icon="line-icons:general:download-01"
                            @click="onDownloadCodes"
                        >
                            {{ $t('users.twoFactorAuth.downloadCodes') }}
                        </base-button>
                        <base-button
                            variant="default"
                            icon="line-icons:media-&-devices:printer"
                            @click="printContent('codes')"
                        >
                            {{ $t('users.twoFactorAuth.printCodes') }}
                        </base-button>
                    </div>
                    <div class="mb-4">
                        {{ $t('users.twoFactorAuth.generateCodesNotice') }}
                    </div>
                    <div class="flex gap-3">
                        <base-button
                            variant="danger"
                            icon="line-icons:general:slash-circle-01"
                            @click="disable2FA"
                        >
                            {{ $t('users.twoFactorAuth.disableAuth') }}
                        </base-button>
                        <base-button
                            @click="regenCodes"
                            variant="primary"
                            icon="line-icons:security:key-01"
                        >
                            {{ $t('users.twoFactorAuth.generateCode') }}
                        </base-button>
                    </div>
                </template>
            </base-section>
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { printContent } from '@tenant/utils/helper'
import { useApi } from '@tenant/composables'

const router = useRouter()
const { t } = useI18n()
useForm()

const { user } = useAuth()
watchEffect(() => {
    if (!user.value?.two_factor_enabled) {
        router.push({ name: 'profile' })
    }

    if (!user.value?.two_factor_confirmed_at) {
        router.push({ name: 'two-factor-auth.create' })
    }
})

const { errorNotify, successNotify } = useNotification()
const emitter = useEmitter()
const { execute: executeGetRecoveryCodes, rawResult: recoveryCodes } = useApi(
    '/user/two-factor-recovery-codes',
    'GET'
)
onMounted(async () => {
    try {
        emitter.emit('set-loading', true)
        await executeGetRecoveryCodes()
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

// DISABLE TWO FACTOR AUTH
const store = useStore()

const { execute: executeDisable2FA } = useApi(
    '/user/two-factor-authentication',
    'DELETE'
)
const disable2FA = async () => {
    try {
        emitter.emit('set-loading', true)
        await executeDisable2FA()
        store.commit('setUser', {
            ...user.value,
            two_factor_enabled: false,
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: DISABLE TWO FACTOR AUTH

// REGEN RECOVERY CODES
const { execute: executeRegenCodes } = useApi(
    '/user/two-factor-recovery-codes',
    'POST'
)
const regenCodes = async () => {
    try {
        emitter.emit('set-loading', true)
        await executeRegenCodes()
        await executeGetRecoveryCodes()

        successNotify({
            text: t('users.twoFactorAuth.regenCodesSuccess'),
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: REGEN RECOVERY CODES

const onCopyCodes = () => {
    navigator.clipboard.writeText(recoveryCodes.value.join('\n'))

    successNotify({
        text: t('users.twoFactorAuth.copyCodesSuccess'),
    })
}

const onDownloadCodes = () => {
    const element = document.createElement('a')
    const file = new Blob([recoveryCodes.value.join('\n')], {
        type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'recovery-codes.txt'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
}
</script>
