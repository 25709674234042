<template>
    <base-section
        :title="$t('users.form.languageSection.title')"
        :description="$t('users.form.languageSection.description')"
    >
        <template #right>
            <form-control-group-row>
                <form-single-select
                    v-model="language"
                    :label="$t('users.form.language')"
                    :options="userLanguages"
                    :disabled="disabled"
                />
            </form-control-group-row>
        </template>
    </base-section>
</template>

<script setup>
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { SYSTEM_LANGUAGES } from '@tenant/utils/constants'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { t } = useI18n()
const { value: language } = useField('language_code')

const userLanguages = SYSTEM_LANGUAGES.map(({ id, locale }) => ({
    value: id,
    label: t(locale),
}))
</script>
