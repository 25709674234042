export default {
    users: {
        form: {
            salutation: 'Salutation *',
            firstName: 'First name *',
            lastName: 'Last name *',
            abbreviation: 'Abbreviation',
            email: 'Email *',
            roles: 'Roles *',
            language: 'Language',
            admin: 'Admin',
            auditor: 'Auditor',

            generalSection: {
                title: 'General information',
                description:
                    'You can create a new user and assign to a specific role.',
            },
            accountSection: {
                title: 'Account information',
                description:
                    'The user wil get an confirmation email to be able to login.',
            },
            avatarSection: {
                title: 'Avatar',
                description: 'Upload your avatar in .png or .jpg format.',
            },
            languageSection: {
                title: 'User interface language',
                description: 'This language is only applied to you',
            },
        },

        profile: {
            title: 'User Profile',
            description: 'You can update your information and settings',
            enable2faTitle: 'Two-factor authentication (2FA)',
            enable2faDescription:
                "Increase your accounts's security by enabling two-factor authentication(2FA).",
            enable2faButton: 'Enable two-factor authentication',
            manage2faButton: 'Manage two-factor authentication',

            changePassword: {
                title: 'Change Password',
                description01:
                    'In order to protect your account, make sure your password:',
                description02: '- Is longer than 7 characters',
                description03:
                    '- Does not match or significantly contain your username, e.g. do not use ‘username123’',
                currentPassword: 'Current Password *',
                newPassword: 'New Password *',
                reNewPassword: 'Re-enter New Password *',
            },
        },

        twoFactorAuth: {
            title: 'Two-Factor Authentication',
            description: 'You can update your information and settings.',
            sectionTitle: 'Register Two-Factor Authenticator',
            sectionDescription:
                'Use a one-time password authenticator on your mobile device or computer to enable two-factor authentication (2FA).Use a one-time password authenticator on your mobile device or computer to enable two-factor authentication (2FA).',
            stepsTitle:
                'Follow the steps below to set up two-factor authenticator:',
            stepOne: '1. Download a two-factor authenticator app',
            iosAuthy:
                'iPhone, iPod Touch, or iPad: <a href="https://apps.apple.com/us/app/twilio-authy/id494168017" class="text-primary-700 underline">Authy for iOS</a>',
            androidAuthy:
                'Android devices: <a href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en&gl=US" class="text-primary-700 underline">Authy for Android</a>',
            windowsDevices:
                'Windows devices: <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" class="text-primary-700 underline">Microsoft Authenticator</a>',
            stepTwo: '2. Scan this QR code with your authenticator app',
            cantScanCode: "Can't scan the code?",
            addManuallyDetails:
                'To add the entry manually, provide the following details to the application on your phone.',
            account: '<span class="font-bold">Account:</span> {account}',
            key: '<span class="font-bold">Key:</span> {key}',
            timeBased: '<span class="font-bold">Time based:</span> {timeBased}',
            stepThree: '3. Enter the resulting verification code',
            pinCodeLabel: 'Pin code *',
            currentPasswordLabel: 'Current password *',
            registerTwoFactorApp: 'Register with two factor app',
            alreadyEnabled:
                "You've already enabled two-factor authentication using one time password authenticators. In order to register a different device, you must first disable two-factor authentication.",
            recoveryCodes:
                'Should you ever lose your phone or access to your one time password secret, each of these recovery codes can be used one time each to regain access to your account. Please save them in a safe place, or you will lose access to your account.',
            copyCodes: 'Copy codes',
            downloadCodes: 'Download codes',
            printCodes: 'Print codes',
            generateCodesNotice:
                'If you lose your recovery codes you can generate new ones, invalidating all previous codes.',
            disableAuth: 'Disable two-factor authentication',
            generateCode: 'Generate recovery code',
            regenCodesSuccess: 'Recovery codes regenerated successfully',
            copyCodesSuccess: 'Recovery codes copied successfully',
        },

        message: {
            editSuccess: 'Save user success',
        },
    },
}
