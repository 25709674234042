<template>
    <base-sticky-heading
        :title="$t('users.twoFactorAuth.title')"
        :description="$t('users.twoFactorAuth.description')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="default"
                    @click="$router.push({ name: 'profile' })"
                >
                    {{ $t('general.cancel') }}
                </base-button>
            </div>
        </template>

        <div class="px-6">
            <base-section
                :title="$t('users.twoFactorAuth.sectionTitle')"
                :description="$t('users.twoFactorAuth.sectionDescription')"
                class="text-gray-700"
            >
                <template #right>
                    <div class="mb-4 text-sm">
                        {{ $t('users.twoFactorAuth.stepsTitle') }}
                    </div>
                    <div class="mb-4 font-bold">
                        {{ $t('users.twoFactorAuth.stepOne') }}
                    </div>
                    <div class="mb-4 rounded-xl border px-3 py-2 text-sm">
                        <p
                            class="mb-2"
                            v-html="$t('users.twoFactorAuth.iosAuthy')"
                        />
                        <p
                            class="mb-2"
                            v-html="$t('users.twoFactorAuth.androidAuthy')"
                        />
                        <p v-html="$t('users.twoFactorAuth.windowsDevices')" />
                    </div>

                    <div class="mb-4 font-bold">
                        {{ $t('users.twoFactorAuth.stepTwo') }}
                    </div>
                    <div class="mb-4 flex gap-3 text-sm">
                        <div
                            v-if="QRCode"
                            class="basis-1/5"
                            v-html="QRCode.svg"
                        ></div>
                    </div>
                    <div class="mb-4 font-bold">
                        {{ $t('users.twoFactorAuth.stepThree') }}
                    </div>
                    <div class="mb-4 flex gap-3">
                        <form-text-input
                            v-model="pinCode"
                            :error-message="errorCode"
                            :label="$t('users.twoFactorAuth.pinCodeLabel')"
                            @input="onInput"
                            required
                        />
                    </div>

                    <base-button
                        icon="line-icons:security:key-01"
                        :label="$t('users.twoFactorAuth.registerTwoFactorApp')"
                        @click="submitTwoFactorAuthApp"
                    />
                </template>
            </base-section>
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { useApi } from '@tenant/composables'

const emitter = useEmitter()
const store = useStore()
const { user } = useAuth()
const router = useRouter()

watchEffect(() => {
    if (user.value?.two_factor_confirmed_at) {
        router.push({ name: 'two-factor-auth.edit' })
    }
})

const { execute: confirm2FA } = useApi(
    '/user/confirmed-two-factor-authentication',
    'POST'
)
const { execute: executeGet } = useApi('/api/info', 'GET')
const submitTwoFactorAuthApp = async () => {
    try {
        emitter.emit('set-loading', true)

        await confirm2FA({
            data: { code: pinCode.value },
        })

        // Re-fetch user info to get the updated two_factor_confirmed_at
        const response = await executeGet()
        if (response?.user?.two_factor_confirmed_at) {
            store.commit('setUser', {
                ...user.value,
                two_factor_confirmed_at: response.user.two_factor_confirmed_at,
            })
            await router.push({ name: 'two-factor-auth.edit' })
        }
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
}

const { execute: getQRCode, rawResult: QRCode } = useApi(
    '/user/two-factor-qr-code',
    'GET'
)
onMounted(async () => {
    await getQRCode()
})

const { setErrors } = useForm()
const { value: pinCode, errorMessage: errorCode } = useField('code')

const onInput = (event) => {
    pinCode.value = event.target.value.replace(/[^0-9]/g, '')
}
</script>
