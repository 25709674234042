<template>
    <base-sticky-heading
        :title="$t('users.profile.title')"
        :description="$t('users.profile.description')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="state.loading"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <div class="px-6">
            <users-section-general />
            <users-section-language />
            <users-section-account disabled />
            <users-section-avatar />

            <base-section>
                <template #right>
                    <base-button
                        icon="line-icons:security:key-01"
                        :label="$t('users.profile.changePassword.title')"
                        @click="isShowChangePasswordModal = true"
                    />
                </template>
            </base-section>

            <base-section
                :title="$t('users.profile.enable2faTitle')"
                :description="$t('users.profile.enable2faDescription')"
            >
                <template #right>
                    <base-button
                        v-if="
                            user?.two_factor_enabled &&
                            user?.two_factor_confirmed_at
                        "
                        icon="line-icons:security:key-01"
                        :label="$t('users.profile.manage2faButton')"
                        @click="goTo2FAEdit"
                    />

                    <base-button
                        v-else
                        icon="line-icons:security:key-01"
                        :label="$t('users.profile.enable2faButton')"
                        @click="enable2FA"
                    />
                </template>
            </base-section>

            <users-change-password-modal
                :show="isShowChangePasswordModal"
                @modal-close="isShowChangePasswordModal = false"
                @modal-success="isShowChangePasswordModal = false"
            />
        </div>
    </base-sticky-heading>
</template>

<script setup>
const { t } = useI18n()
const router = useRouter()
const emitter = useEmitter()
const { successNotify, errorNotify } = useNotification()

const { setValues, setErrors, handleSubmit } = useForm()

const { uploadStorage } = useUploadStorage()
const { execute: executeGet } = useApi('/api/info', 'GET')
const { execute: executeSave } = useApi('/api/info', 'POST')

useHead({ title: t('users.profile.title') })

const isShowChangePasswordModal = ref(false)

const state = reactive({
    loading: false,
})

onMounted(() => {
    emitter.emit('set-loading', true)

    executeGet().then((response) => {
        setValues(response.user)
    })

    emitter.emit('set-loading', false)
})

const onSubmit = handleSubmit(async ({ avatar, ...values }) => {
    try {
        state.loading = true
        let uploadKey = null
        if (avatar) {
            uploadKey = await uploadStorage(avatar)
        }

        await executeSave({
            data: {
                ...values,
                avatar: uploadKey,
            },
        })

        successNotify({
            title: t('general.success'),
            text: t('users.message.editSuccess'),
        })
    } catch ({ errors, errorMessage }) {
        errorNotify({
            title: t('general.error'),
            text: errorMessage,
        })
        setErrors(errors)
    } finally {
        state.loading = false
    }
})

// ENABLE 2FA
const { user } = useAuth()
const store = useStore()
const { execute: executeEnable2FA } = useApi(
    '/user/two-factor-authentication',
    'POST'
)
const enable2FA = async () => {
    try {
        emitter.emit('set-loading', true)

        if (!user.value?.two_factor_enabled) {
            await executeEnable2FA()
            store.commit('setUser', {
                ...user.value,
                two_factor_enabled: true,
            })
        }
        await router.push({ name: 'two-factor-auth.create' })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: ENABLE 2FA

const goTo2FAEdit = () => {
    router.push({ name: 'two-factor-auth.edit' })
}
</script>
