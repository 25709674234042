<template>
    <base-section
        :title="$t('users.form.accountSection.title')"
        :description="$t('users.form.accountSection.description')"
    >
        <template #right>
            <form-control-group-row>
                <form-text-input
                    v-model="email"
                    :label="$t('users.form.email')"
                    :disabled="disabled"
                />
            </form-control-group-row>
        </template>
    </base-section>
</template>

<script setup>
import { useField } from 'vee-validate'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { value: email } = useField('email')
</script>
