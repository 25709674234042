<template>
    <base-modal close @modal-close="closeModal" :show="show" size="sm">
        <template #modal-icon>
            <div class="flex">
                <div
                    class="rounded-full border-8 border-primary-50 bg-primary-100 p-1.5"
                >
                    <base-icon name="line-icons:security:key-01" />
                </div>
            </div>
        </template>
        <div class="flex flex-col">
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('users.profile.changePassword.title') }}
            </h5>
            <p class="mt-2 text-sm font-normal text-gray-500">
                {{ $t('users.profile.changePassword.description01') }}
            </p>
            <p class="text-sm font-normal text-gray-500">
                {{ $t('users.profile.changePassword.description02') }}
            </p>
            <p class="text-sm font-normal text-gray-500">
                {{ $t('users.profile.changePassword.description03') }}
            </p>

            <div class="mt-4">
                <form-text-input
                    v-model="currentPassword"
                    :label="$t('users.profile.changePassword.currentPassword')"
                    :error-message="currentPasswordError"
                    type="password"
                />
            </div>
            <div class="mt-4">
                <form-text-input
                    v-model="newPassword"
                    :label="$t('users.profile.changePassword.newPassword')"
                    :error-message="newPasswordError"
                    type="password"
                />
            </div>
            <div class="my-4">
                <form-text-input
                    v-model="reNewPassword"
                    :label="$t('users.profile.changePassword.reNewPassword')"
                    :error-message="reNewPasswordError"
                    type="password"
                />
            </div>
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    full-width
                    :loading="loadingChangePassword"
                    @click="onSubmit"
                >
                    {{ $t('general.change') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'confirm-delete'])

defineProps({
    show: {
        type: Boolean,
        default: true,
    },
})

const { setErrors, handleSubmit } = useForm()
const { value: currentPassword, errorMessage: currentPasswordError } =
    useField('password')
const { value: newPassword, errorMessage: newPasswordError } =
    useField('new_password')
const { value: reNewPassword, errorMessage: reNewPasswordError } = useField(
    'new_password_confirmation'
)

const { loading: loadingChangePassword, execute: executeChangePassword } =
    useApi('/api/info/change-password', 'POST')
const { logout } = useAuthHelper()

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = handleSubmit((values) => {
    executeChangePassword({ data: values })
        .then(() => {
            emit('modal-success')
            logout()
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})
</script>
